<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Extra
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="extra-form">
          <v-text-field
            label="Product"
            outlined
            v-model="fields.name"
            background-color="white"
            type="text"
          ></v-text-field>
          <v-textarea
            label="Descriptin"
            rows="4"
            v-model="fields.description"
            background-color="white"
            required
            outlined
            dense
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-text-field
            label="Price *"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            v-model="fields.unit_cost"
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Total Quantity *"
            type="number"
            min="0"
            step="1"
            outlined
            v-model="fields.total_quantity"
            background-color="white"
          ></v-text-field>
          <v-switch
            label="Is multiple?"
            v-model="fields.is_multiple"
            color="green"
            class="mt-1 mb-4"
            inset
          ></v-switch>
          <v-file-input
            label="Image"
            v-model="fields.thumbnail_image"
            accept="image/png, image/jpeg"
            placeholder="Choose Image"
            outlined
            background-color="white"
            @change="thumbnailHasChanged()"
            :error="errors.hasOwnProperty('thumbnail_image')"
            :error-messages="errors['thumbnail_image']"
          ></v-file-input>
          <v-switch
            label="Active?"
            v-model="fields.is_active"
            color="green"
            class="mt-1 mb-4"
            inset
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="extra-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      file: null,
      extra: {},
      errors: {},
      fields: {
        name: null,
        description: "",
        unit_cost: "",
        total_quantity: 0,
        is_multiple: false,
        is_active: false,
        thumbnail_image: null,
        thumbnail_has_changed: false,
      },
    };
  },

  methods: {
    open(extra = null) {
      if (extra !== null) {
        this.extra = extra;
        this.isEditing = true;
        this.fields.name = extra.name;
        this.fields.unit_cost = extra.unit_cost;
        this.fields.total_quantity = extra.total_quantity;
        this.fields.is_multiple = extra.is_multiple;
        this.fields.is_active = extra.is_active;

        this.fields.description = extra.description;

        this.fields.thumbnail_image = extra.thumbnail_image
          ? new File([extra.thumbnail_image], extra.thumbnail_image)
          : null;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.extraId = this.extra.id;
      }

      payload.thumbnail_has_changed = this.fields.thumbnail_has_changed ? 1 : 0;

      if (this.fields.thumbnail_has_changed) {
        payload.thumbnail_image = this.fields.thumbnail_image;
      }

      this.$store
        .dispatch("arnprior/bookingsStore/saveExtra", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.extra = {};
      this.errors = {};
      this.fields.name = null;
      this.fields.unit_cost = "";
      this.fields.description = "";
      this.fields.total_quantity = "";
      this.fields.is_multiple = false;
      this.fields.is_active = false;
      this.fields.thumbnail_has_changed = false;
      this.fields.thumbnail_image = null;
      this.$refs.formWrapper.scrollTop = 0;
    },

    thumbnailHasChanged() {
      this.fields.thumbnail_has_changed = true;
    },
  },
};
</script>
