<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Extras</h1>
        </v-col>
        <v-col class="pl-12"> </v-col>
        <v-col cols="auto" class="col col-auto">
          <v-btn
            depressed
            color="accent"
            class=""
            @click="$refs.extraDialog.open()"
          >
            <v-icon small>mdi-plus</v-icon> Add an extra
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-toolbar flat dark dense color="accent">
          <v-toolbar-title>Product List</v-toolbar-title>
          <div class="spacer"></div>
        </v-toolbar>
        <v-data-table
          :headers="tableHeaders"
          :items="extras"
          :item-class="itemRowBackground"
          class="mt-4"
          no-data-text="No Extras found"
        >
          <template v-slot:item.price="{ item }"> £{{ item.price }} </template>

          <template v-slot:item.active="{ item }">
            <div v-if="item.is_active == 1">Active</div>
            <div v-else>Inactive</div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  depressed
                  color="blue lighten-4 blue--text"
                  v-on="on"
                  class="mr-2"
                  @click="$refs.extraDialog.open(item)"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  v-on="on"
                  @click="openDelete(item)"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>Archive</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <extra-dialog ref="extraDialog" />
    <v-dialog v-model="deleteExtra.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"
          >Delete {{ deleteExtra.extra.name }}</v-card-title
        >
        <v-card-text> Are you sure you want to delete this extra?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteExtra.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExtraDialog from "./components/ExtraDialog";

export default {
  components: {
    ExtraDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Glamping Pods",
          disabled: true,
        },
        {
          text: "Extras",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Product", value: "name" },
        { text: "Description", value: "description" },
        { text: "Price", value: "unit_cost" },
        { text: "Status", value: "active" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteExtra: {
        dialog: false,
        extra: {},
        loading: false,
      },
    };
  },

  computed: {
    extras() {
      return this.$store.getters["arnprior/bookingsStore/extras"];
    },
  },

  methods: {
    itemRowBackground: function (item) {
      if (item.is_active == 0) {
        return "red lighten-4";
      }
    },

    openDelete(extra) {
      this.deleteExtra.extra = extra;
      this.deleteExtra.dialog = true;
    },

    resetDelete() {
      this.deleteExtra.dialog = false;
      this.deleteExtra.extra = {};
      this.deleteExtra.loading = false;
    },

    saveDelete() {
      this.deleteExtra.loading = true;

      this.$store
        .dispatch("arnprior/bookingsStore/deleteExtra", {
          appId: this.$route.params.id,
          extraId: this.deleteExtra.extra.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteExtra.loading = false;
        });
    },
  },
};
</script>
